<template>
  <Spin v-if="!datas.id" fix></Spin>
  <div class="cont" v-else>
    <div
      class="bg"
      :style="{
        background: `url(${datas.cover.url}) no-repeat center`,
        backgroundSize: 'cover'
      }"
    >
      <!-- <h1>A Short Headline Here</h1>
      <h3>A one setence description sub headline here.</h3>
      <div class="callbtn">
        <Button><strong>Call to Action</strong></Button>
      </div> -->
      <div class="avatar">
        <img alt="" v-if="datas.company" :src="datas.company.avatar.url" />
      </div>
    </div>
    <div class="content" v-if="datas.id">
      <!-- <a href=""><Icon type="md-arrow-back" /> Return to Marketing</a> -->
      <h3 class="title">
        {{ datas.title }}
      </h3>
      <div class="desc">
        {{ datas.description }}
      </div>
      <div
        class="file"
        id="file"
        v-if="datas.file && datas.file.url.indexOf('.pdf') > -1"
      >
        <div class="tit">
          File Name: <strong>{{ datas.file.name }}</strong>
          <a :href="datas.file.url" v-if="datas.file" target="_blank"
            ><Icon type="md-download" /> Download File</a
          >
        </div>
        <div class="filec" style="position:relative;">
          <!-- <pdf
            ref="pdf"
            :src="pdfUrl"
            v-for="item in numPages"
            :key="item"
            :page="item"
          /> -->
          <!-- <Spin v-if="datas.id && pdfLoading" fix></Spin> -->
          <div
            v-if="pdfLoading"
            class="not"
            style="position:absolute;top:0px;width:100%;text-align:center;"
          >
            Loading...
          </div>
          <pdf
            v-for="i in numPages"
            :key="i"
            :page="i"
            :src="pdfUrl"
            @num-pages="pageCount = $event"
          >
          </pdf>
        </div>
      </div>
      <div class="not" v-else>
        <p>The file is not <strong>PDF</strong></p>
        <img
          :src="datas.file.url"
          alt=""
          style="width:100%;margin-top:10px;border:1px solid #f2f2f2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import pdf from "vue-pdf";
const { _queryMarketing } = api;

export default {
  components: {
    pdf
  },
  data() {
    return {
      pdfLoading: false,
      datas: {},
      pageCount: 1,
      src: "",
      numPages: 1,
      pdfUrl:
        "https://connexima.s3-us-west-1.amazonaws.com/documents/w73WBbaGrHtTqFtfGuoCLD.pdf"
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.id = id;
    this.pdfLoading = true;
    _queryMarketing({ id })
      .then(response => {
        const { data } = response;
        this.datas = data;
        this.loadPdfHandler(data.file.url);
      })
      .catch(error => {
        console.log(error);
      });
  },
  methods: {
    async loadPdfHandler(src) {
      this.pdfUrl = pdf.createLoadingTask(src);
      this.pdfUrl._capability.promise.then(pdff => {
        this.numPages = pdff.numPages > 20 ? 20 : pdff.numPages;
        setTimeout(() => {
          this.pdfLoading = false;
        }, 3000);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.cont {
  width: 1200px;
  margin: 20px auto;
  border: 1px solid rgb(204, 204, 204);
  padding: 20px;
  background: #fff;
  .bg {
    position: relative;
    width: 100%;
    height: 200px;
    background-color: #ff954e;
    margin-bottom: 60px;
    color: #fff;
    text-align: center;
    .avatar {
      position: absolute;
      left: 50%;
      margin-left: -50px;
      bottom: -38px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
      // border: 1px solid #ccc;
      margin-right: 10px;
      background-color: #fff;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .content {
    width: 800px;
    margin: 0 auto;
    text-align: center;
    .title {
      font-size: 24px;
    }
    .desc {
      margin-top: 10px;
    }
    .not {
      padding: 50px 0;
      font-size: 16px;
      color: #ff8080;
    }
    .file {
      margin-top: 30px;
      box-shadow: 0 0 8px #f2f2f2;
      .tit {
        text-align: left;
        border: 1px solid #ccc;
        padding: 10px;
        a {
          float: right;
          color: @primary-color;
          .ivu-icon {
            font-size: 20px;
          }
        }
      }
      .filec {
        border: 1px solid #ccc;
        border-top: none;
        height: 600px;
        overflow: auto;
        overflow-x: hidden;
        .canvas {
          width: 100%;
        }
      }
    }
  }
}
</style>
